import React from 'react';
import ExcelImportEnrollmentMetricsTeacherStudent from '../ExcelImportEnrollmentMetricsTeacherStudent';
import '../App.css';

function ImportEnrollmentMetricsTeacherStudent() {
  return (
    <div className="App">
      <h1>Import Enrollment Metrics Teacher & Student</h1>
      <p>Link API: https://bi.testprep-edupath.org.vn/api/enrollment_metrics_teacher_student</p>
      <ExcelImportEnrollmentMetricsTeacherStudent/>
    </div>
  );
}

export default ImportEnrollmentMetricsTeacherStudent;