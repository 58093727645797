// src/components/NavBar.js
import React from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css'; // Import the CSS file for styling

const NavBar = () => {
  return (
    <nav className="NavBar">
      <ul>
        <li><Link to="/import">Import SC Payment Detail MTD</Link></li>
        <li><Link to="/importenrollmentdetail">Import SC Enrollment Detail MTD</Link></li>
        <li><Link to="/ImportEnrollmentMetricsStudentClass">Import Enrollment Metrics Student & Class</Link></li>
        <li><Link to="/ImportEnrollmentMetricsTeacherClass">Import Enrollment Metrics Teacher & Class</Link></li>
        <li><Link to="/ImportEnrollmentMetricsTeacherStudent">Import Enrollment Metrics Teacher & Student</Link></li>
        <li><Link to="/ImportLessonDetailByStaff">Import Lesson Detail By Staff</Link></li>
        <li><Link to="/ImportEnrollmentDetailNew">Import Enrollment Detail</Link></li>
        <li><Link to="/ImportLastEnrollment">Import Last Enrollment</Link></li>
        <li><Link to="/ImportFirstEnrollment">Import First Enrollment</Link></li>
        <li><Link to="/generate-key">Generate API Key</Link></li>
        <li><Link to="/registration">Registration</Link></li>
      </ul>
    </nav>
  );
};

export default NavBar;
