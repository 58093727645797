import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';
import './App.css'; // Import your CSS file

function ExcelImportEnrollmentDetailNew() {
  const [data, setData] = useState([]);
  const [message, setMessage] = useState('');

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      
      // Convert the sheet to JSON, starting from row 3
      const jsonData = XLSX.utils.sheet_to_json(sheet, {
        header: 1, // We get the rows as arrays
        range: 3, // Start from the 3rd row (0-indexed, so range: 2 is actually row 3)
      });

      // Process the data to only take the columns you want
      const filteredData = jsonData.map(row => ({
        StudentName: row[0], // Column A
        StudentNumber: row[1],     // Column B
        Name: row[2], // Column C
        BranchName: row[3], // Column D
        Attendance: row[4]   // Column F
      }));

      setData(filteredData);
      console.log('Filtered JSON data from Excel:', filteredData); // Debugging line
    };

    reader.readAsBinaryString(file);
  };

  const sendDataToBackend = async () => {
    try {
      if (!Array.isArray(data) || data.length === 0) {
        setMessage('No data to import');
        return;
      }
      console.log('Data to be sent:', JSON.stringify(data, null, 2)); // Log data before sending

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/import/enrollment_detail`, data);

      setMessage('Data imported successfully!');
      console.log('Response from backend:', response.data);
    } catch (error) {
      console.error('Error sending data:', error.message); // Log detailed error
      if (error.response) {
        console.error('Response status:', error.response.status); // Log response status
        console.error('Response data:', error.response.data); // Log error data from server
        setMessage('Error importing data');
      } else {
        setMessage('Error importing data');
      }
    }
  };

  return (
    <div className="excel-import-container">
      <input type="file" onChange={handleFileUpload} />
      {data.length > 0 && (
        <div>
          <h2>Imported Data:</h2>
          <button onClick={sendDataToBackend}>Send Data to Backend</button>
        </div>
      )}
      <p>{message}</p>
    </div>
  );
}

export default ExcelImportEnrollmentDetailNew;
