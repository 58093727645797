import React, { useEffect, useState } from 'react';
import axios from 'axios';
import NavBar from './NavBar';
import '../App.css'; // Import your CSS file

const Layout = ({ children }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/data`;
        const response = await axios.get(apiUrl);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="App">
      <NavBar />
      <main className="content">
        {data ? (
          <div className="data-container">
            {/* Example: Displaying data */}
            {data.map((item, index) => (
              <div key={index}>
                {/* Display item details */}
                {item.name} {/* Add your item display logic here */}
              </div>
            ))}
          </div>
        ) : (
          <p className="loading-message">Loading...</p>
        )}
        {children}
      </main>
    </div>
  );
};

export default Layout;
