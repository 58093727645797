import React from 'react';
import ExcelImportLessonDetailByStaff from '../ExcelImportLessonDetailByStaff';
import '../App.css';

function ImportLessonDetailByStaff() {
  return (
    <div className="App">
      <h1>Import Lesson Detail By Staff</h1>
      <p>Link API: https://bi.testprep-edupath.org.vn/api/lesson_detail_by_staff</p>
      <ExcelImportLessonDetailByStaff/>
    </div>
  );
}

export default ImportLessonDetailByStaff;