import React from 'react';
import ExcelImportEnrollmentMetricsStudentClass from '../ExcelImportEnrollmentMetricsStudentClass';
import '../App.css';

function ImportEnrollmentMetricsStudentClass() {
  return (
    <div className="App">
      <h1>Import Enrollment Metrics Student & Class</h1>
      <p>Link API: https://bi.testprep-edupath.org.vn/api/enrollment_metrics_student_class</p>
      <ExcelImportEnrollmentMetricsStudentClass/>
    </div>
  );
}

export default ImportEnrollmentMetricsStudentClass;