import React from 'react';
import ExcelImportEnrollmentDetailNew from '../ExcelImportEnrollmentDetailNew';
import '../App.css';

function ImportEnrollmentDetailNew() {
  return (
    <div className="App">
      <h1>Import Enrollment Detail</h1>
      <p>Link API: https://bi.testprep-edupath.org.vn/api/import_enrollment_detail</p>
      <ExcelImportEnrollmentDetailNew/>
    </div>
  );
}

export default ImportEnrollmentDetailNew;