// src/components/Version.js
import React from 'react';

const Version = () => {
  const version = process.env.REACT_APP_VERSION || 'N/A';

  return (
    <div className="version">
      Version: {version}
    </div>
  );
};

export default Version;
