import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';
import './App.css'; // Import your CSS file

function ExcelImportEnrollmentMetricsStudentClass() {
  const [data, setData] = useState([]);
  const [message, setMessage] = useState('');

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
  
    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      
      // Convert the sheet to JSON, starting from row 3
      const jsonData = XLSX.utils.sheet_to_json(sheet, {
        header: 1, // We get the rows as arrays
        range: 3, // Start from the 3rd row (0-indexed, so range: 2 is actually row 3)
      });
  
      // Process the data to only take the columns you want
      const filteredData = jsonData.map(row => ({
        StaffName: row[0], // Column A
        Class: row[1],     // Column B
        LessonCount: row[2], // Column C
        StudentCount: row[3], // Column D
        TotalLessonHours: row[4], // Column E
        AggregatedLessonHours: row[5], // Column F
        Present: row[6],   // Column G
        Absent: row[7],    // Column H
        Leave_: row[8],    // Column I
        SickLeave: row[9], // Column J
        Paid: row[10]      // Column K
      }));
  
      // Split data into chunks of 100 rows (or any preferred size)
      const chunkSize = 100;
      const chunkedData = [];
      for (let i = 0; i < filteredData.length; i += chunkSize) {
        chunkedData.push(filteredData.slice(i, i + chunkSize));
      }
  
      setData(chunkedData); // Store chunked data in state
      console.log('Filtered JSON data from Excel:', chunkedData); // Debugging line
    };
  
    reader.readAsBinaryString(file);
  };
  

  const sendDataToBackend = async () => {
    try {
      if (!Array.isArray(data) || data.length === 0) {
        setMessage('No data to import');
        return;
      }
  
      // Send each chunk sequentially
      for (const chunk of data) {
        console.log('Sending chunk:', JSON.stringify(chunk, null, 2)); // Log data before sending
  
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/import/enrollment_metrics_student_class`, chunk);
        
        console.log('Response from backend for chunk:', response.data);
      }
  
      setMessage('All data imported successfully!');
    } catch (error) {
      console.error('Error sending data:', error.message); // Log detailed error
      if (error.response) {
        console.error('Response status:', error.response.status); // Log response status
        console.error('Response data:', error.response.data); // Log error data from server
        setMessage('Error importing data');
      } else {
        setMessage('Error importing data');
      }
    }
  };
  

  return (
    <div className="excel-import-container">
      <input type="file" onChange={handleFileUpload} />
      {data.length > 0 && (
        <div>
          <h2>Imported Data:</h2>
          <button onClick={sendDataToBackend}>Send Data to Backend</button>
        </div>
      )}
      <p>{message}</p>
    </div>
  );
}

export default ExcelImportEnrollmentMetricsStudentClass;
