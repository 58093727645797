import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';
import './App.css'; // Import your CSS file

const ExcelImportEnrollmentDetail = () => {
  const [dataSheet1, setDataSheet1] = useState([]);
  const [dataSheet2, setDataSheet2] = useState([]);
  const [message, setMessage] = useState('');

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });

      const sheetName1 = workbook.SheetNames[0];
      const sheet1 = workbook.Sheets[sheetName1];
      const jsonData1 = XLSX.utils.sheet_to_json(sheet1);

      const sheetName2 = workbook.SheetNames[1];
      const sheet2 = workbook.Sheets[sheetName2];
      const jsonData2 = XLSX.utils.sheet_to_json(sheet2);

      setDataSheet1(jsonData1);
      setDataSheet2(jsonData2);
      console.log('JSON data from Sheet 1:', jsonData1); // Debugging line
      console.log('JSON data from Sheet 2:', jsonData2); // Debugging line
    };

    reader.readAsBinaryString(file);
  };

  const convertExcelDate = (excelDate) => {
    const epoch = new Date(Date.UTC(1899, 11, 30));
    const date = new Date(epoch.getTime() + excelDate * 86400000);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const processAndSendDataToBackend = async () => {
    try {
      if (!Array.isArray(dataSheet1) || dataSheet1.length === 0 || !Array.isArray(dataSheet2) || dataSheet2.length === 0) {
        setMessage('No data to import');
        return;
      }

      // Example of processing the data based on conditions
      const transformedData = dataSheet1.map(row1 => {
        const matchingRow2 = dataSheet2.find(row2 => row2['studentNumber'] === row1['studentNumber'] && row2['class'] === row1['class']);
        if (matchingRow2) {
          return {
            studentNumber: row1['studentNumber'],
            studentName: row1['studentName'],
            dates: convertExcelDate(row1['dates']),
            branchName: row1['branchName'],
            staff: row1['staff'],
            class: row1['class'],
            lessonCount: matchingRow2['lessonCount'],
            aggLessonHours: matchingRow2['aggLessonHours'],
            present: matchingRow2['present'],
            absent: matchingRow2['absent'],
            f_leave: matchingRow2['f_leave'] // Add data from sheet2
          };
        }
        return {
          ...row1,
          dates: convertExcelDate(row1['dates'])
        }; // If no match, return row1 data with converted date
      });

      console.log('Transformed data:', transformedData); // Debugging line

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/importenrollmentdetail`, transformedData);

      setMessage('Data imported successfully!');
      console.log('Response from backend:', response.data);
    } catch (error) {
      if (error.response && error.response.status === 409) {
        const duplicates = error.response.data.duplicates;
        setMessage(`Duplicate data found: ${JSON.stringify(duplicates)}`);
      } else {
        console.error('There was an error importing the data!', error.response ? error.response.data : error.message);
        setMessage('Error importing data');
      }
    }
  };

  return (
    <div className="excel-import-container">
      <input type="file" onChange={handleFileUpload} />
      {dataSheet1.length > 0 && dataSheet2.length > 0 && (
        <div>
          <h2>Imported Data:</h2>
          <button onClick={processAndSendDataToBackend}>Send Data to Backend</button>
        </div>
      )}
      <p>{message}</p>
    </div>
  );
};

export default ExcelImportEnrollmentDetail;
