import React from 'react';
import ExcelImportLastEnrollment from '../ExcelImportLastEnrollment';
import '../App.css';

function ImportLastEnrollment() {
  return (
    <div className="App">
      <h1>Import Last Enrollment</h1>
      <p>Link API: https://bi.testprep-edupath.org.vn/api/last_enrollment</p>
      <ExcelImportLastEnrollment/>
    </div>
  );
}

export default ImportLastEnrollment;