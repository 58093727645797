// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/NavBar.css */

.NavBar {
  background-color: #333;
  overflow: hidden;
}

.NavBar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.NavBar li {
  display: inline;
}

.NavBar li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 20px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.NavBar li a:hover {
  background-color: #575757;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/NavBar.css"],"names":[],"mappings":"AAAA,8BAA8B;;AAE9B;EACE,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,SAAS;EACT,UAAU;EACV,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,qBAAqB;EACrB,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd","sourcesContent":["/* src/components/NavBar.css */\n\n.NavBar {\n  background-color: #333;\n  overflow: hidden;\n}\n\n.NavBar ul {\n  list-style-type: none;\n  margin: 0;\n  padding: 0;\n  display: flex;\n  justify-content: center;\n}\n\n.NavBar li {\n  display: inline;\n}\n\n.NavBar li a {\n  display: block;\n  color: white;\n  text-align: center;\n  padding: 14px 20px;\n  text-decoration: none;\n  transition: background-color 0.3s;\n}\n\n.NavBar li a:hover {\n  background-color: #575757;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
