import React from 'react';
import ExcelImportEnrollmentDetail from '../ExcelImportEnrollmentDetail';
import '../App.css';

function ImportEnrollmentDetail() {
  return (
    <div className="App">
      <h1>Import SC Enrollment Detail MTD</h1>
      <p>Link API: https://bi.testprep-edupath.org.vn/api/data/enrollmentdetail</p>
      <ExcelImportEnrollmentDetail />
    </div>
  );
}

export default ImportEnrollmentDetail;