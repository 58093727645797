import React from 'react';
import ExcelImport from '../ExcelImport';
import '../App.css';

function Import() {
  return (
    <div className="App">
      <h1>Import SC Payment Detail MTD</h1>
      <p>Link API: https://bi.testprep-edupath.org.vn/api/data</p>
      <ExcelImport />
    </div>
  );
}

export default Import;