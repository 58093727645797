import React from 'react';
import ExcelImportEnrollmentMetricsTeacherClass from '../ExcelImportEnrollmentMetricsTeacherClass';
import '../App.css';

function ImportEnrollmentMetricsTeacherClass() {
  return (
    <div className="App">
      <h1>Import Enrollment Metrics Teacher & Class</h1>
      <p>Link API: https://bi.testprep-edupath.org.vn/api/enrollment_metrics_teacher_class</p>
      <ExcelImportEnrollmentMetricsTeacherClass/>
    </div>
  );
}

export default ImportEnrollmentMetricsTeacherClass;