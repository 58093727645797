import React from 'react';
import ExcelImportFirstEnrollment from '../ExcelImportFirstEnrollment';
import '../App.css';

function ImportFirstEnrollment() {
  return (
    <div className="App">
      <h1>Import First Enrollment</h1>
      <p>Link API: https://bi.testprep-edupath.org.vn/api/first_enrollment</p>
      <ExcelImportFirstEnrollment/>
    </div>
  );
}

export default ImportFirstEnrollment;