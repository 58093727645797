// src/components/GenerateApiKey.js

import React, { useState } from 'react';
import axios from 'axios';
import './GenerateApiKey.css'; // Import CSS file

const GenerateApiKey = () => {
  const [clientName, setClientName] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [error, setError] = useState('');

  const handleGenerateKey = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/generate-key`, { clientName });
      setApiKey(response.data.apiKey);
      setError('');
    } catch (error) {
      console.error('Error generating API key:', error.response ? error.response.data : error.message);
      setError('Error generating API key');
    }
  };

  return (
    <div className="generate-api-key-container">
      <h1>Generate API Key</h1>
      <input
        type="text"
        placeholder="Client Name"
        value={clientName}
        onChange={(e) => setClientName(e.target.value)}
      />
      <button onClick={handleGenerateKey}>Generate Key</button>
      {apiKey && (
        <div>
          <h2>Your API Key:</h2>
          <pre>{apiKey}</pre>
        </div>
      )}
      {error && (
        <div className="error-message">
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export default GenerateApiKey;
