import React from 'react';
import Registration from './Registration';
import '../App.css';

function Registrationform() {
  return (
    <div className="App">
      <Registration />
    </div>
  );
}

export default Registrationform;