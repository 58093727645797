// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Registrationform from './components/Registrationform';
import Import from './components/Import';
import ImportEnrollmentDetail from './components/ImportEnrollmentDetail';
import ImportEnrollmentMetricsStudentClass from './components/ImportEnrollmentMetricsStudentClass';
import ImportEnrollmentMetricsTeacherClass from './components/ImportEnrollmentMetricsTeacherClass';
import ImportEnrollmentMetricsTeacherStudent from './components/ImportEnrollmentMetricsTeacherStudent';
import ImportLessonDetailByStaff from './components/ImportLessonDetailByStaff';
import ImportEnrollmentDetailNew from './components/ImportEnrollmentDetailNew';
import ImportLastEnrollment from './components/ImportLastEnrollment';
import ImportFirstEnrollment from './components/ImportFirstEnrollment';
import Layout from './components/Layout';
import './App.css';
import Version from './components/Version';
import GenerateApiKey from './components/GenerateApiKey';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/login"
            element={isLoggedIn ? <Navigate to="/import" /> : <Login setIsLoggedIn={setIsLoggedIn} />}
          />
          <Route
            path="/import"
            element={isLoggedIn ? <Layout><Import /></Layout> : <Navigate to="/login" />}
          />
          <Route
            path="/importenrollmentdetail"
            element={isLoggedIn ? <Layout><ImportEnrollmentDetail /></Layout> : <Navigate to="/login" />}
          />
          <Route
            path="/ImportEnrollmentMetricsStudentClass"
            element={isLoggedIn ? <Layout><ImportEnrollmentMetricsStudentClass /></Layout> : <Navigate to="/login" />}
          />
          <Route
            path="/ImportEnrollmentMetricsTeacherClass"
            element={isLoggedIn ? <Layout><ImportEnrollmentMetricsTeacherClass /></Layout> : <Navigate to="/login" />}
          />
          <Route
            path="/ImportEnrollmentMetricsTeacherStudent"
            element={isLoggedIn ? <Layout><ImportEnrollmentMetricsTeacherStudent /></Layout> : <Navigate to="/login" />}
          />
          <Route
            path="/ImportLessonDetailByStaff"
            element={isLoggedIn ? <Layout><ImportLessonDetailByStaff /></Layout> : <Navigate to="/login" />}
          />
          <Route
            path="/ImportEnrollmentDetailNew"
            element={isLoggedIn ? <Layout><ImportEnrollmentDetailNew /></Layout> : <Navigate to="/login" />}
          />
          <Route
            path="/ImportLastEnrollment"
            element={isLoggedIn ? <Layout><ImportLastEnrollment /></Layout> : <Navigate to="/login" />}
          />
          <Route
            path="/ImportFirstEnrollment"
            element={isLoggedIn ? <Layout><ImportFirstEnrollment /></Layout> : <Navigate to="/login" />}
          />
          <Route path="/generate-key"
           element={isLoggedIn ? <Layout><GenerateApiKey /></Layout> : <Navigate to="/login" />}
           />
          <Route
            path="/registration"
            element={isLoggedIn ? <Layout><Registrationform /></Layout> : <Navigate to="/login" />}
          />
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
      </div>
      <main className="content">
        <Version />
        {/* Other components */}
      </main>
    </Router>
  );
}

export default App;
