import React, { useState } from 'react';
import axios from 'axios';
import '../App.css'; // Import your CSS file

function Login({ setIsLoggedIn }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleLogin = () => {
    console.log('API URL:', process.env.REACT_APP_API_URL);
    axios.post(`${process.env.REACT_APP_API_URL}/api/login`, { username, password })
      .then(response => {
        if (response.data.success) {
          setIsLoggedIn(true);
        } else {
          setMessage('Invalid credentials');
        }
      })
      .catch(error => {
        setMessage('There was an error with the login request');
        console.error('Error details:', error);
      });
  };

  return (
    <div className="login-container">
      <h1>Login</h1>
      <input
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        placeholder="Username"
        className="login-input"
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
        className="login-input"
      />
      <button onClick={handleLogin} className="login-button">Login</button>
      {message && <p className="login-message">{message}</p>}
    </div>
  );
}

export default Login;
